<template>
  <div class="leadership-page fade-up">
    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedLeaders()"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <h2>Рахбарият</h2>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="dialogNewVisible = true"
          >
            Янги қушиш
          </el-button>
        </div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          style="width: 100%"
          :data="leaders"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="full_name" label="ФИШ"></el-table-column>
          <el-table-column prop="position" label="Лавозим"></el-table-column>
          <el-table-column prop="rank" label="Ранк"></el-table-column>
          <el-table-column prop="phone" label="Телефон рақам"></el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="editMemberHandler(scope.row)"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteMember(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      class="dialog-sm"
      width="350px"
      title="Янги рахбар"
      :visible.sync="dialogNewVisible"
    >
      <el-card class="fade-up">
        <div class="el-card-header">
          <drop-file-input
            v-model="newMember.image"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="ФИШ"
            v-model="newMember.full_name"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Лавозим"
            v-model="newMember.position"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Ранк"
            v-model="newMember.rank"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Телефон рақам"
            type="tel"
            v-model="newMember.phone"
          />
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNewVisible = false">Бекор қилиш</el-button>
        <el-button
          type="primary"
          :disabled="Object.values(newMember).some(v => !v)"
          @click="addMember"
        >
          Қушиш
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog-sm"
      width="350px"
      title="Рахбарни тахрирлаш"
      :visible.sync="dialogEditVisible"
    >
      <el-card class="fade-up">
        <div class="el-card-header">
          <drop-file-input
            :init-photo="editMember.image"
            v-model="editMember.image"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="ФИШ"
            v-model="editMember.full_name"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Лавозим"
            v-model="editMember.position"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Ранк"
            v-model="editMember.rank"
            :style="{ marginBottom: '10px' }"
          />
          <el-input
            placeholder="Телефон рақам"
            type="tel"
            v-model="editMember.phone"
          />
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">Бекор қилиш</el-button>
        <el-button
          type="primary"
          :disabled="Object.values(editMember).some(v => !v)"
          @click="updateMember"
        >
          Сақлаш
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DropFileInput from "@/components/DropFileInput";

export default {
  components: {
    DropFileInput
  },
  data: () => ({
    isLoading: false,
    dialogNewVisible: false,
    dialogEditVisible: false,
    newMember: {
      image: null,
      full_name: "",
      position: "",
      rank: "",
      phone: ""
    },
    editMember: {
      image: null,
      full_name: "",
      position: "",
      rank: "",
      phone: ""
    },
    multipleSelection: []
  }),
  computed: {
    leaders() {
      return this.$store.state.leaderModule.leaders;
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    this.$store.dispatch("fetchLeaders").finally(() => loading.close());
  },
  methods: {
    updateMember() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.dialogEditVisible = false;
      const formData = new FormData();
      const data = { ...this.editMember };
      delete data.image;
      formData.append("data", JSON.stringify(data));
      if (
        typeof this.editMember.image !== "string" &&
        this.editMember.image !== null
      ) {
        formData.append("files.image", this.editMember.image);
      }
      this.$store
        .dispatch("updateLeader", { data: formData, id: this.editMember.id })
        .then(() => {
          this.$message({
            type: "success",
            message: "Сақланди"
          });
        })
        .finally(() => {
          this.editMember.image = null;
          this.editMember.full_name = "";
          this.editMember.position = "";
          this.editMember.rank = "";
          this.editMember.phone = "";
          this.isLoading = false;
        });
    },
    deleteMember(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteLeader", row.id)
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteSelectedLeaders() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteLeader", r.id);
            })
          )
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    addMember() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.dialogNewVisible = false;
      const data = { ...this.newMember };
      delete data.image;
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("files.image", this.newMember.image);
      this.$store
        .dispatch("addLeader", formData)
        .then(() => {
          this.$message({
            type: "success",
            message: "Қушилди"
          });
        })
        .finally(() => {
          this.newMember.image = null;
          this.newMember.full_name = "";
          this.newMember.position = "";
          this.newMember.rank = "";
          this.newMember.phone = "";
          this.isLoading = false;
        });
    },
    editMemberHandler(item) {
      this.editMember = { ...item };
      this.editMember.image = this.$baseUrl + item.image.url;
      this.dialogEditVisible = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.leadership-page {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
